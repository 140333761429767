import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import TodayIcon from '@mui/icons-material/Today';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import VerticalShadesIcon from '@mui/icons-material/VerticalShades';
import CelebrationIcon from '@mui/icons-material/Celebration';

export default function Services() {
	return (
		<Box sx={{ flexGrow: 1, padding: 2 }}>
			<Grid container spacing={2}>
				{/* Column 1 */}
				<Grid item xs={12} sm={6} md={4} lg={2.4}>
					<Box
						sx={{
							height: '100%', // Ensures the Box takes the full height
							backgroundColor: '#FDFEFF', // Background color for visibility
							display: 'flex', // Enables flexbox for centering
							flexDirection: 'column',
							alignItems: 'center', // Centers text vertically
							padding: 2, // Padding inside the Box
							borderRadius: '5px',
						}}>
						<Box
							sx={{
								height: '70px',
								'@media (max-width:420px)': {
									height: '40px', // Height for tablets
								},
							}}>
							<Typography variant='h3' lineHeight='1.2'>
								Full service Event Planning
							</Typography>
						</Box>
						<Box>
							<CelebrationIcon sx={{ fontSize: 50, color: '#F7DD7D' }} />{' '}
							{/* Placeholder icon */}
						</Box>
						<Typography variant='body1' sx={{ fontSize: '14px' }}>
							Comprehensive event planning and production from start to finish.
							We manage venue and vendor selection, ensuring a stress-free
							experience with our expert event management skills.
						</Typography>
					</Box>
				</Grid>

				{/* Column 2 */}
				<Grid item xs={12} sm={6} md={4} lg={2.4}>
					<Box
						sx={{
							height: '100%', // Ensures the Box takes the full height
							backgroundColor: '#FDFEFF', // Background color for visibility
							display: 'flex', // Enables flexbox for centering
							flexDirection: 'column',
							alignItems: 'center', // Centers text vertically
							padding: 2, // Padding inside the Box
							borderRadius: '5px',
						}}>
						<Box
							sx={{
								height: '70px',
								'@media (max-width:420px)': {
									height: '50px', // Height for tablets
								},
							}}>
							<Typography variant='h3' lineHeight='1.2'>
								Day of Coordination and Partial Planning{' '}
							</Typography>
						</Box>
						<Box>
							<TodayIcon
								sx={{
									fontSize: 50,
									color: '#F7DD7D',
								}}
							/>{' '}
							{/* Placeholder icon */}
						</Box>
						<Typography variant='body1'>
							Relax and enjoy your event while we handle coordination. We’ll
							meet 30 days prior to understand your vision and ensure a
							stress-free, memorable day with seamless supplier and venue
							management.
						</Typography>
					</Box>
				</Grid>

				{/* Column 3 */}
				<Grid item xs={12} sm={6} md={4} lg={2.4}>
					<Box
						sx={{
							height: '100%', // Ensures the Box takes the full height
							backgroundColor: '#FDFEFF', // Background color for visibility
							display: 'flex', // Enables flexbox for centering
							flexDirection: 'column',
							alignItems: 'center', // Centers text vertically
							padding: 2, // Padding inside the Box
							borderRadius: '5px',
						}}>
						<Box
							sx={{
								height: '70px',
								'@media (max-width:420px)': {
									height: '40px', // Height for tablets
								},
							}}>
							<Typography variant='h3'>Backdrop Decor </Typography>
						</Box>
						<Box>
							<VerticalShadesIcon sx={{ fontSize: 50, color: '#F7DD7D' }} />{' '}
							{/* Placeholder icon */}
						</Box>
						<Typography variant='body1'>
							Add the WOW factor to your event with our stunning backdrops,
							perfect for selfie stations and Instagram-worthy decor. Ideal for
							baby showers, birthdays, weddings, or any celebration that needs a
							stylish touch!
						</Typography>
					</Box>
				</Grid>

				{/* Column 4 */}
				<Grid item xs={12} sm={6} md={4} lg={2.4}>
					<Box
						sx={{
							height: '100%', // Ensures the Box takes the full height
							backgroundColor: '#FDFEFF', // Background color for visibility
							display: 'flex', // Enables flexbox for centering
							flexDirection: 'column',
							alignItems: 'center', // Centers text vertically
							padding: 2, // Padding inside the Box
							borderRadius: '5px',
						}}>
						<Box
							sx={{
								height: '70px',
								'@media (max-width:420px)': {
									height: '40px', // Height for tablets
								},
							}}>
							<Typography variant='h3' lineHeight='1.2'>
								Prop Hire and Balloon Decor
							</Typography>
						</Box>
						<Box>
							<LocalFloristIcon sx={{ fontSize: 50, color: '#F7DD7D' }} />{' '}
							{/* Placeholder icon */}
						</Box>
						<Typography variant='body1'>
							Enhance your decor with our Marquee Lights or elevate your theme
							with festive balloon decor. Perfect for adding style and
							excitement to any event, making your celebration truly
							unforgettable.
						</Typography>
					</Box>
				</Grid>

				{/* Column 5 */}
				<Grid item xs={12} sm={6} md={4} lg={2.4}>
					<Box
						sx={{
							height: '100%', // Ensures the Box takes the full height
							backgroundColor: '#FDFEFF', // Background color for visibility
							display: 'flex', // Enables flexbox for centering
							flexDirection: 'column',
							alignItems: 'center', // Centers text vertically
							padding: 2, // Padding inside the Box
							borderRadius: '5px',
						}}>
						<Box
							sx={{
								height: '70px',
								'@media (max-width:420px)': {
									height: '40px', // Height for tablets
								},
							}}>
							<Typography variant='h3'>Event Design </Typography>
						</Box>
						<Box>
							<DesignServicesIcon sx={{ fontSize: 50, color: '#F7DD7D' }} />{' '}
							{/* Placeholder icon */}
						</Box>
						<Typography variant='body1'>
							We transform your venue with creative decor, bringing your dream
							event to life. Our designs create unforgettable moments, leaving a
							lasting impression on both you and your guests.
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}
