import React from 'react';
import { Box, Typography, Link, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import Logo from './Assets/Logo/LogoForWeb.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

export default function Footer() {
	return (
		<Box sx={{ padding: 4 }}>
			<Grid container spacing={2}>
				{/* First Column (50% width on larger screens) */}
				<Grid item xs={12} md={6}>
					<Grid
						container
						direction='column'
						// Full height and center content
						alignItems='center'>
						{/* Logo */}
						<Grid item>
							<Box
								loading='lazy'
								component='img'
								src={Logo} // Add your logo image source here
								alt='Company Logo'
								sx={{ width: 150, marginBottom: 2 }} // Set width for logo and margin
							/>
						</Grid>

						{/* Address */}
						<Grid item>
							<Typography variant='body1' gutterBottom>
								Rose Cottage, Warraton Lane, Cornwall, UK
							</Typography>
						</Grid>

						{/* Telephone Number */}
						<Grid item>
							<Typography variant='body1' gutterBottom>
								Telephone: +44 7310 009764
							</Typography>
						</Grid>

						{/* Email */}
						<Grid item>
							<Typography variant='body1' gutterBottom>
								Email: info@ansomevents.com
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				{/* Second Column (25% width on larger screens) */}
				{/* <Grid item xs={12} md={3}>
					<Box
						sx={{
							borderRadius: '8px',
							textAlign: 'center',
						}}>
						<Grid
							container
							direction='column'
							justifyContent='flex-start'
							alignItems='flex-start'
							sx={{ padding: 2 }} // Add padding for spacing
						>
							<Grid item>
								<Link href='#home' underline='none' sx={{ padding: 1 }}>
									<Typography variant='p'>Home</Typography>
								</Link>
							</Grid>

							<Grid item>
								<Link href='#about' underline='none' sx={{ padding: 1 }}>
									<Typography variant='p'>About Us</Typography>
								</Link>
							</Grid>
							<Grid item>
								<Link href='#services' underline='none' sx={{ padding: 1 }}>
									<Typography variant='p'>Services</Typography>
								</Link>
							</Grid>

							<Grid item>
								<Link href='#contact' underline='none' sx={{ padding: 1 }}>
									<Typography variant='p'>Contact</Typography>
								</Link>
							</Grid>
						</Grid>
					</Box>
				</Grid> */}

				{/* Third Column (25% width on larger screens) */}
				<Grid item xs={12} md={3}>
					<Grid
						container
						spacing={1}
						justifyContent='center'
						alignItems='center'
						sx={{ padding: 2 }} // Add padding for spacing
					>
						{/* Facebook */}
						<Grid item>
							<Link
								href='https://www.facebook.com/profile.php?id=61566721061832'
								target='_blank'
								rel='noopener'
								underline='none'>
								<IconButton aria-label='Facebook' sx={{ color: '#4267B2' }}>
									<FacebookIcon fontSize='large' />
								</IconButton>
							</Link>
						</Grid>
						{/* Instagram */}
						<Grid item>
							<Link
								href='https://www.instagram.com/ansomeventsuk/'
								target='_blank'
								rel='noopener'
								underline='none'>
								<IconButton aria-label='Instagram' sx={{ color: '#E4405F' }}>
									<InstagramIcon fontSize='large' />
								</IconButton>
							</Link>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}
