import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
	palette: {
		primaryBlue: {
			main: '#7096CE',
			secondary: '#8DB7E1',
		},
		secondaryYellow: {
			main: '#FFC413',
			secondary: '#F7DD7D',
		},
		error: {
			main: '#f44336', // Customize error color
		},
		background: {
			default: '#f5f5f5', // Customize background color
			paper: '#ffffff', // Customize paper color
		},
		lightBlack: {
			main: '#2F2E2C',
		},
		darkBlack: {
			main: '#050505',
		},
	},
	typography: {
		responsiveFontSizes: true,
		fontFamily: 'Roboto, sans-serif', // Customize font family
		fontSize: 14, // Customize base font size
		h1: {
			fontSize: '4rem', // Default font size for small screens
			fontWeight: 600,
			color: '#F7DD7D',
			textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)',
		},
		h2: {
			fontSize: '2rem', // Default font size for small screens
			fontWeight: 600,
			color: '#F7DD7D',
			textShadow: '1px 1px 2.5px rgba(0, 0, 0, 0.3)',
		},
		h3: {
			fontSize: '1.3rem', // Default font size for small screens
			fontWeight: 200,
			color: '#2F2E2C',
		},
		body1: {
			fontSize: '14px', // Custom font size for <p> tags
			lineHeight: '1.6', // Custom line height
			fontWeight: '400', // Custom font weight
			color: '#333', // Custom text color
			fontFamily: 'Montserrat,sans-serif', // Custom font family
			textAlign: 'left',
		},
		// Add more typography styles as needed
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '8px', // Customize button border radius
				},
			},
		},
		// Add more component customizations as needed
	},
});

export default theme;
