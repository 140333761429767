import React, { useState } from 'react';
import { Box, Grid, TextField, Button, Typography } from '@mui/material';
import QuestionAndAnswers from '../QuestionsAndAnswers';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';

export default function Contact() {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		telephone: '',
		comment: '',
	});

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// Add form submission logic here
		console.log(formData);
	};
	const [open, setOpen] = useState(false);
	// Handle modal open/close
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<Box sx={{ padding: 4 }}>
			<Grid container spacing={2}>
				{/* First Column */}
				<Grid item xs={12} md={6}>
					<Box
						sx={{
							backgroundColor: '#f0f0f0',
							padding: 2,
							borderRadius: '8px',
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<Typography variant='h2' sx={{ fontSize: '40px' }} gutterBottom>
							Have A Question?
						</Typography>
						<Typography variant='h2' sx={{ fontSize: '40px' }} gutterBottom>
							Read Our FAQs
						</Typography>
						<Button variant='contained' color='#8DB7E1' onClick={handleOpen}>
							Here
						</Button>
						<Dialog
							open={open}
							onClose={handleClose} // Closes the modal when clicking outside or pressing ESC
							scroll='paper' // Or use scroll="body" based on preference
							maxWidth='md'
							fullWidth
							aria-labelledby='modal-title'
							aria-describedby='modal-description'>
							{/* Modal content */}
							<DialogTitle id='modal-title' variant='h6' component='h2'>
								Frequently Asked Questions (FAQ)
							</DialogTitle>
							<DialogContent>
								<QuestionAndAnswers />
							</DialogContent>

							{/* Button to close the modal */}
							<DialogActions>
								<Button
									onClick={handleClose}
									sx={{ mt: 2, cursor: 'pointer' }}
									variant='outlined'
									color='secondary'>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</Box>
				</Grid>

				{/* Second Column */}
				{/* <Grid item xs={12} md={6}>
					<Box
						sx={{
							maxWidth: 600,
							margin: '0 auto',
							padding: 4,
							backgroundColor: '#f9f9f9',
							borderRadius: 2,
							boxShadow: 1,
						}}>
						<Typography variant='h4' gutterBottom>
							Contact Us
						</Typography>
						<form onSubmit={handleSubmit}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<TextField
										fullWidth
										label='Name'
										name='name'
										value={formData.name}
										onChange={handleChange}
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										label='Email'
										name='email'
										type='email'
										value={formData.email}
										onChange={handleChange}
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										label='Telephone'
										name='telephone'
										type='tel'
										value={formData.telephone}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										label='Comment'
										name='comment'
										multiline
										rows={4}
										value={formData.comment}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12}>
									<Button
										fullWidth
										variant='contained'
										backgroundColor='#8DB7E1'
										type='submit'>
										Submit
									</Button>
								</Grid>
							</Grid>
						</form>
					</Box>
				</Grid> */}
			</Grid>
		</Box>
	);
}
