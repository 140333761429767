// src/components/NavBar.js
import React, { useState } from 'react';
import {
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Box,
	Grid,
	Drawer,
	List,
	ListItem,
	ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom';

import Logo from './Assets/Logo/AnsomEvents.svg';

const Navbar = () => {
	const [drawerOpen, setDrawerOpen] = useState(false);

	const handleDrawerToggle = () => {
		setDrawerOpen(!drawerOpen);
	};

	return (
		<>
			<AppBar
				position='static'
				sx={{
					backgroundColor: 'transparent', // Set transparent background
					boxShadow: 'none', // Remove the default box shadow
				}}>
				<Toolbar>
					{/* Grid container for 3 columns */}
					<Grid container alignItems='center'>
						{/* First Column: Navigation Links */}
						<Grid
							item
							xs={8}
							md={4}
							sx={{ display: { xs: 'none', md: 'block' } }}>
							<Box display='flex' gap={2}>
								<Typography
									variant='h6'
									component={Link}
									to='/'
									sx={{
										textDecoration: 'none',
										color: 'lightBlack.main',
										'&:hover': {
											textDecoration: 'none',
											color: 'darkBlack.main',
										},
									}}>
									Home
								</Typography>
								{/* <Typography variant='h6' color='lightBlack.main'>
									About
								</Typography>
								<Typography
									variant='h6'
									color='lightBlack.main'
									component={Link}
									to='/services'>
									Services
								</Typography> */}
							</Box>
						</Grid>

						{/* Second Column: Company Logo */}
						<Grid
							item
							xs={10}
							md={4}
							sx={{ textAlign: 'center', padding: '0.5em' }}>
							{/* Display the logo image */}
							<Box
								loading='lazy'
								component='img'
								src={Logo}
								alt='Company Logo'
								sx={{ height: 100, width: 'auto' }}
							/>
						</Grid>

						{/* Third Column: Social Media Links */}
						<Grid
							item
							md={4}
							sx={{
								display: { xs: 'none', md: 'flex' },
								justifyContent: 'flex-end',
							}}>
							<IconButton
								edge='end'
								color='lightBlack.main'
								component='a'
								href='https://www.facebook.com/profile.php?id=61566721061832' // Replace with your Facebook page URL
								target='_blank' // Opens the link in a new tab
								rel='noopener noreferrer' // For security (prevents the new page from accessing your window object)
								aria-label='Facebook'>
								<FacebookIcon />
							</IconButton>
							{/* <IconButton edge='end' color='lightBlack.main'>
								<TwitterIcon />
							</IconButton> */}
							<IconButton
								edge='end'
								color='lightBlack.main'
								component='a'
								href='https://www.instagram.com/ansomeventsuk/' // Replace with your Facebook page URL
								target='_blank' // Opens the link in a new tab
								rel='noopener noreferrer' // For security (prevents the new page from accessing your window object)
								aria-label='Instagram'>
								<InstagramIcon />
							</IconButton>
						</Grid>

						{/* Hamburger Icon for Small Screens */}
						<Grid
							item
							xs={2}
							sx={{ display: { md: 'none' }, textAlign: 'right' }}>
							<IconButton
								edge='start'
								color='inherit'
								aria-label='menu'
								onClick={handleDrawerToggle}>
								<MenuIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>

			{/* Drawer for Hamburger Menu */}
			<Drawer anchor='right' open={drawerOpen} onClose={handleDrawerToggle}>
				<Box sx={{ width: 250 }}>
					<List>
						<ListItem button onClick={handleDrawerToggle}>
							<ListItemText primary='Home' />
						</ListItem>
						<ListItem button onClick={handleDrawerToggle}>
							<ListItemText primary='About' />
						</ListItem>
						<ListItem button onClick={handleDrawerToggle}>
							<ListItemText primary='Services' />
						</ListItem>
					</List>
				</Box>
			</Drawer>
		</>
	);
};

export default Navbar;
