import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import us from '../../../Assets/Photos/HomePageImages/OwnersPhoto3.jpg';
export default function About() {
	return (
		<Box sx={{ padding: 4 }}>
			<Grid container spacing={2} alignItems='center'>
				{/* First Column: Image */}
				<Grid item xs={12} md={6}>
					<Box
						loading='lazy'
						component='img'
						src={us} // Replace with your image URL
						alt='Example'
						sx={{ width: '80%', height: 'auto', borderRadius: '8px' }} // Ensures responsive image
					/>
				</Grid>

				{/* Second Column: Text */}
				<Grid item xs={12} md={6}>
					<Typography variant='h2' gutterBottom marginBlock={5}>
						About Us
					</Typography>
					<Typography variant='body1' textAlign='left' margin={1}>
						We are a husband and wife team based in Cornwall, offering full
						event management, prop hire, and venue design. With over 20 years of
						experience, Melissa brings her extensive knowledge of the events
						industry, while Thomas’s expertise in design and marketing ensures
						every detail is both creative and polished.
					</Typography>
					<Typography textAlign='left' margin={1}>
						At our core, we’re passionate about inspiring and crafting memorable
						events, from weddings to corporate functions. Whether you're looking
						for expert management or unique props to bring your vision to life,
						we’re here to make your event unforgettable.
					</Typography>
					<Typography textAlign='left' margin={1}>
						From concept to completion, we focus on delivering a stress-free and
						enjoyable experience. Let us help you create the perfect event,
						tailored to your style and needs.
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
}
