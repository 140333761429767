import React from 'react';
import { Box, Typography } from '@mui/material';
import hero from '../../../Assets/Photos/HomePageImages/Hero.jpg';

export default function Hero() {
	return (
		<Box
			sx={{
				// Use vh and vw units for full height and width
				height: '100vh', // Full viewport height
				width: '100%', // Full viewport width
				backgroundImage: `url(${hero})`, // Replace with your image URL
				backgroundSize: 'cover', // Cover the entire area
				backgroundPosition: 'center', // Center the image
				backgroundRepeat: 'no-repeat', // Prevent image repetition
				display: 'flex', // Enable flex for centering content
				alignItems: 'center', // Center vertically
				justifyContent: 'center', // Center horizontally
				color: 'white', // Text color
				textAlign: 'center', // Center text
				overflow: 'hidden',
			}}>
			<Box>
				<Typography variant='h1'>Dream Big With Ansom Events!</Typography>
			</Box>
		</Box>
	);
}
