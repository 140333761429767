import React from 'react';
import { Grid2, Box } from '@mui/material';
import one from './../../../Assets/Photos/Gallery/PinkWhiteGold.jpg';
import two from './../../../Assets/Photos/Gallery/5yearOld.jpg';
import three from './../../../Assets/Photos/Gallery/BluePinkGrey.jpg';
import four from './../../../Assets/Photos/Gallery/BirthdayTables.Flags.jpg';

const images = [
	{
		src: one,
		alt: 'Pink, White and Gold ballon garland set up by Ansom Events',
		title: 'Pink, White and Gold ballon garland set up by Ansom Events',
	},
	{
		src: two,
		alt: '5 year old child Birthday ballon set up by Ansom Events',
		title: '5 year old child Birthday ballon set up by Ansom Events',
	},
	{
		src: three,
		alt: 'Blue, Pink and Grey Ballon Garland set up by Ansom Events',
		title: 'Blue, Pink and Grey Ballon Garland set up by Ansom Events',
	},
	{ src: four, alt: 'Birthday Set Up', title: 'Birthday Set Up' },
];
export default function Gallery() {
	return (
		<Box sx={{ padding: 4 }}>
			<Grid2 container spacing={2} justifyContent={'space-evenly'}>
				{images.map((image, index) => (
					<Grid2 item='true' xs={12} sm={6} md={4} lg={2.4} key={index}>
						<Box
							component='img'
							src={image.src}
							alt={image.alt}
							title={image.title}
							loading='lazy'
							sx={{ borderRadius: '8px', height: '100%', width: '300px' }}
						/>
					</Grid2>
				))}
			</Grid2>
		</Box>
	);
}
